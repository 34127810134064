<template>
  <Container :visible="visible" class="select-reports-termial">
    <Row label="开始时间">
      <DatePicker />
    </Row>
    <Row label="结束时间">
      <DatePicker />
    </Row>
    <Row label="竞品品牌" class="brand">
      <Picker :data="brands" />
    </Row>
  </Container>
</template>

<script>
import Row from '../row';
import Picker from '../picker';
import Container from '../container';
import DatePicker from '../datePicker';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Row,
    Picker,
    Container,
    DatePicker,
  },
  data() {
    return {
      brands: ['品牌1', '品牌2', '品牌3', '品牌4']
    }
  }
}
</script>

<style lang="scss" scoped>
.select-reports-termial {
  .brand {
    margin-top: 12px;
  }
}
</style>