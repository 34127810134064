<template>
  <div class="common-select-row" @click="$emit('click')">
    <div class="label" :class="{ required: required }">{{ label }}</div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.common-select-row {
  width: 100%;
  height: 40px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 24px;
  box-sizing: border-box;
  position: relative;
  &::after {
    content: '';
    width: calc(100% - 40px);
    box-sizing: border-box;
    border-bottom: 1px solid #f5f6f7;;
    position: absolute;
    bottom: 0;
  }
  .label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #818181;
    &.required {
      &::before {
        content: "*";
        margin-right: 4px;
        color: #cd001f;
      }
    }
  }
}
</style>
