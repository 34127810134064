<template>
  <div class="common-select-container" v-if="visible">
    <Header :text="noConfirm? '' : '确定'" @textClick="onConfirm"/>
    <slot />
  </div>
</template>

<script>
import Header from '@/components/header/headerWithText';
export default {
  props: {
    visible: {
      type: Boolean,
      defaulut: false,
    },
    noConfirm: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Header,
  },
  methods: {
    onConfirm() {
      this.$emit('confirm');
    }
  }
}
</script>

<style lang="scss" scoped>
.common-select-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  overflow-y: auto;
  top: 0;
  left: 0;
  background-color: #FAFBFC;
  z-index: 10;
}
</style>